import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@material-ui/core';
import { Fields } from '../Fields';
import { EMOJ, FIELD_TYPES } from '../../utils/utils';
import { PersonalJourneyClasses } from '../../styles/PersonalJourneyClasses';
import MarketingEvent from './MarketingEvent';
import JSData from '../JSData';

export default function MarketingPostsDialog(props) {
    const objectToEdit = props.objectToEdit
    const versionToEdit = props.versionToEdit
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [savedObjectData, setSavedObjectData] = useState({});
    const [newData, setNewData] = useState({});
    const [mode, setMode] = useState();
    const [disabled, setDisabled] = useState(false);
    const [editVersion, setEditVersion] = useState();
    const [objectToEditPublish, setObjectToEditPublish] = useState();
    const [activeInput, setActiveInput] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);

    const [textToShow, setTextToShow] = useState();

    const versions = (savedObjectData||{}).versions || [{}]
    const version = versions[tabIndex]

    useEffect(() => {
        if (objectToEdit){
            if (objectToEdit._id){
                setEditId(objectToEdit._id)
                setMode('VIEW')
                setSavedObjectData({...objectToEdit})
                setTextToShow()
                if (versionToEdit !== undefined){
                    setTabIndex(versionToEdit)
                    /*setEditVersion(versionToEdit)
                    setNewData(objectToEdit.versions[versionToEdit])*/
                } else {
                    setTabIndex(objectToEdit.defaultVersion||0)
                }
            } else {
                setEditId(-1)
                setMode('EDIT')
                setNewData({...objectToEdit})
                setError()
            }
        } else {
            setEditId()
        }
    }, [objectToEdit, versionToEdit]);

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    }

    const handleClose = () => {
        if (props.handleSetPost){
            props.handleSetPost(savedObjectData)
        }
        props.setObjectToEdit()
    }

    const handleEdit = () => {
        setNewData({})
        setError()
        setMode('EDIT')
    }

    const handleCancel = () => {
        if (editId === -1){
            handleClose()
        } else {
            setMode('VIEW')
            setError()
            setNewData()
        }
    }

    const handleEditVersion = (data, index) => {
        setEditVersion(index)
        setNewData(data)
    }

    const handleDefaultVersion = () => {
        const params = {
            newData:{defaultVersion:tabIndex}, 
            editId:savedObjectData._id
        }

        setDisabled(true)

        AdminJHApi.createEditMPost(params,(response)=>{
            setSavedObjectData({...response})
            setError()
            setDisabled(false)

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleCloneVersion = () => {
        const params = {
            newData:{versions:[...versions, version]}, 
            editId:savedObjectData._id
        }

        setDisabled(true)

        AdminJHApi.createEditMPost(params,(response)=>{
            setSavedObjectData({...response})
            setError()
            setDisabled(false)

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleSaveVersion = e => {
        e.preventDefault();

        const params = {
            newData:{versions:versions.map((version, index) => (index===editVersion)?newData:version)}, 
            editId:savedObjectData._id
        }

        setDisabled(true)

        AdminJHApi.createEditMPost(params,(response)=>{
            setSavedObjectData({...response})
            setEditVersion()
            setError()
            setNewData()
            setDisabled(false)
            setActiveInput()

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleCancelEditVersion = () => {
        setActiveInput()
        setEditVersion()
        setError()
        setNewData()
    }

    const enterEmoji = textToInsert => {
        if (!activeInput) return;

        const { name, selectionStart, selectionEnd, value } = activeInput;
        const newText = value.substring(0, selectionStart) + textToInsert + value.substring(selectionEnd);
        setNewData({...newData,[name]: newText})

        setTimeout(() => {
            activeInput.selectionStart = selectionStart + textToInsert.length;
            activeInput.selectionEnd = selectionStart + textToInsert.length;
            activeInput.focus();
        }, 0);
    }

    const handleChangeVersionField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleFocus = e => {
        setActiveInput(e.target)
    }

    const handlePublishVersion = () => {
        setObjectToEditPublish({
            status: "Ready",
            postId: savedObjectData._id,
            version: tabIndex,
            posts: [savedObjectData]
        })
    }

    const handleRefreshPublish = () => {
        const params = {
            newData:{}, 
            editId:savedObjectData._id
        }

        setDisabled(true)

        AdminJHApi.createEditMPost(params,(response)=>{
            setSavedObjectData({...response})
            setError()
            setDisabled(false)

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleSave = e => {
        e.preventDefault();

        setDisabled(true)

        AdminJHApi.createEditMPost({newData, editId},(response)=>{
            setSavedObjectData({...response})
            setMode('VIEW')
            setDisabled(false)

            props.handelRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (savedObjectData[name] !== undefined){
            return savedObjectData[name]
        } else {
            return defaultValue
        }
    }

    const getValueVersion = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleEditPublish = (publish, params={}) => [
        setObjectToEditPublish({...publish})
    ]

    const getVersionName = publish => {
        const versionPublish = (publish.version !== undefined)?savedObjectData.versions[publish.version]:{name:'Unknown'}
        return versionPublish.name || (1+publish.version)
    }

    const types = [
        {value: "Review", label:"Review"},
        {value: "Video", label:"Video"},
        {value: "Professional", label:"Professional"},
        {value: "WhatsApp", label:"WhatsApp"},
    ]

    const subTypes = [
        {value: "Ai News", label:"Ai News"},
        {value: "Article", label:"Article"},
        {value: "CV Posts", label:"CV Posts"},
        {value: "Document", label:"Document"},
        {value: "Quotes", label:"Quotes"},
        {value: "Hot Topic", label:"Hot Topic"},
        {value: "Meme", label:"Meme"},
        {value: "Poll", label:"Poll"},
        {value: "Text", label:"Text"},
        {value: "Video", label:"Video"},
    ]

    const statuses = [
        {value: "New", label:"New"},
        {value: "Ready", label:"Ready"},
        {value: "Need Rewrite", label:"Need Rewrite"},
        {value: "Hold", label:"Hold"},
        {value: "Closed", label:"Closed"},
    ]

    let fields = [
        { name: 'name', label: 'Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        { name: 'type', label: 'Type', defaultValue: '', options:types, type:FIELD_TYPES.SELECT, sm:12},
    ]

    if (mode === 'EDIT'){
        if (getValue('type', '') === 'Professional'){
            fields = [
                ...fields,
                { name: 'subType', label: 'Sub Type', defaultValue: '', options:subTypes, type:FIELD_TYPES.SELECT, sm:12}
            ]
        }

        fields = [
            ...fields,
            { name: 'status', label: 'Status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, sm:12}, 
            { name: 'contentLink', label: 'ContentLink', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
            { name: 'isTest', label:'Test', defaultValue: false, type:FIELD_TYPES.CHECK_BOX},
            { name: 'endTest', label:'End Test', defaultValue: '', type:FIELD_TYPES.DATE2, hide: !getValue('isTest', false)},
        ]
    }

    const versionFields = [
        { name: 'name', label: 'Name', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'beforeCTA', label: 'Before CTA', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'afterCTA', label: 'After CTA', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'comments', label: 'Comments', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'reshareText', label: 'ReShare Sentences', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'additionalText', label: 'Additional Text', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
        { name: 'whatChanged', label: 'What Changed', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},  
    ]

    const publisheCells = [
        { id: 'dateTime', align: "left", label: 'Publish', type: 'DATE_TIME'}, 
        { id: 'version', align: "left", label: 'Version', getValue: getVersionName}, 
        { id: 'type', align: "left", label: 'Type'}, 
        { id: 'location', align: "left", label: 'Location'}, 
        { id: 'questionnairesCount', align: "left", label: 'Leads'}, 
        { id: 'impressions', align: "left", label: 'Impressions'}, 
    ]

    const propsToChildrenPublish = {
        handleRefresh: handleRefreshPublish,
        setObjectToEdit: setObjectToEditPublish,
        objectToEdit: objectToEditPublish
    }

    const classes = PersonalJourneyClasses(); 

    return (
        <Dialog onClose={handleClose} open={(editId!==undefined)} fullWidth maxWidth="md">
            {(mode === 'VIEW')&&<>
                <MarketingEvent {...propsToChildrenPublish}/>
                <DialogTitle>{savedObjectData.name} - <strong>{savedObjectData.type}</strong>{savedObjectData.isTest&&<> - Test</>}
                    {activeInput&&<><br/>
                    {EMOJ.map(emoj =><Button key={emoj} size='small' onClick={()=>{enterEmoji(emoj)}}><span role="img" aria-label="emoj">{emoj}</span></Button>)}</>}
                    
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{marginTop:8}}>
                        {(versions.length>1)&&<Tabs value={tabIndex} onChange={handleChangeTab} variant="scrollable" color='primary' indicatorColor="primary">
                            {versions.map((version, index) => (
                                <Tab key={index} label={version.name || ("Version " + (index+1))} value={index} className={classes.journeyTab} disabled={disabled}/>
                            ))}
                        </Tabs>}
                        {(editVersion === undefined)?<>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                <List component="nav" dense>
                                    <ListItem button onClick={()=>setTextToShow()} color='primary' selected={(!textToShow)}>
                                        <ListItemText primary="Content" />
                                    </ListItem>
                                    {version.comments&&<ListItem button onClick={()=>setTextToShow("comments")} selected={(textToShow === 'comments')}>
                                        <ListItemText primary="Comments" />
                                    </ListItem>}
                                    {version.reshareText&&<ListItem button onClick={()=>setTextToShow("reshareText")} selected={(textToShow === 'reshareText')}>
                                        <ListItemText primary="ReShare Sentences" />
                                    </ListItem>}
                                    {version.additionalText&&<ListItem button onClick={()=>setTextToShow("additionalText")} selected={(textToShow === 'additionalText')}>
                                        <ListItemText primary="Additional Text" />
                                    </ListItem>}
                                    {version.whatChanged&&<ListItem button onClick={()=>setTextToShow("whatChanged")} selected={(textToShow === 'whatChanged')}>
                                        <ListItemText primary="What Changed" />
                                    </ListItem>} 
                                </List>
                                </div>
                                <div style={{marginTop: 8}}>
                                    {(!textToShow)&&<Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                        {version.beforeCTA || ''}
                                        <br/>
                                        <a target='_blank' rel="noopener noreferrer" href={'https://jobhunt.co.il/?career&source='}>https://jobhunt.co.il/?career&source=</a>
                                        <br/>
                                        {version.afterCTA || ''}
                                    </Typography>}
                                    {(textToShow === 'comments' && version.comments)&&<>
                                        <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                            {version.comments}
                                        </Typography>
                                    </>}
                                    {(textToShow === 'reshareText' && version.reshareText)&&<>
                                        <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                            {version.reshareText}
                                        </Typography>
                                    </>}
                                    {(textToShow === 'additionalText' && version.additionalText)&&<>
                                        <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                            {version.additionalText}
                                        </Typography>
                                    </>}
                                    {(textToShow === 'whatChanged' && version.whatChanged)&&<>
                                        <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                            {version.whatChanged}
                                        </Typography>
                                    </>}
                                </div>
                            </div>
                            <div style={{marginTop: 8, marginBottom:8}}>
                                <Button style={{marginRight:8}} variant='outlined' disabled={disabled||editVersion !== undefined} onClick={()=>handleEditVersion(version, tabIndex)} color="primary" size="small">
                                    Edit
                                </Button>
                                {(tabIndex !== savedObjectData.defaultVersion)&&<Button style={{marginRight:8}} variant='outlined' disabled={disabled||editVersion !== undefined} onClick={handleDefaultVersion} color="primary" size="small">
                                    Make Default
                                </Button>}
                                <Button style={{marginRight:8}} variant='outlined' disabled={disabled||editVersion !== undefined} onClick={handleCloneVersion} color="primary" size="small">
                                    Clone Version
                                </Button>
                                <Button style={{marginRight:8}} variant='outlined' disabled={disabled||editVersion !== undefined} onClick={handlePublishVersion} color="primary" size="small">
                                    Publish
                                </Button>
                            </div>
                        </>:
                        <form onSubmit={handleSaveVersion} style={{width: '100%'}}>
                            {(error)&&<Typography color="error">{error}</Typography>}
                            <Fields fields={versionFields} getValue={getValueVersion} handleChange={handleChangeVersionField} handleFocus={handleFocus}/>
                            <div style={{textAlign: 'right', marginTop: 8}}>
                                <Button disabled={disabled} onClick={handleCancelEditVersion} color="primary" size="small" aria-label="Cancel">
                                    Cancel
                                </Button>
                                <Button
                                    disabled={disabled}
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    aria-label='Save'
                                >
                                    Save
                                </Button>
                            </div>
                        </form>}
                    </div>
                    <Typography><a target='_blank' rel="noopener noreferrer" href={savedObjectData.contentLink}>Content Link</a></Typography>
                    {(savedObjectData&&savedObjectData.publishes&&savedObjectData.publishes.length>0)&&<JSData cells={publisheCells} data={savedObjectData.publishes} handleEditRow={handleEditPublish} />}
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled||editVersion !== undefined} onClick={handleEdit} color="primary" size="small" aria-label="Edit">
                        Edit
                    </Button>
                    <Button disabled={disabled||editVersion !== undefined} onClick={handleClose} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </>}
            {(mode === 'EDIT')&&<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>}
        </Dialog>
    )
}