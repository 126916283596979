import React, { useState, useEffect, Fragment } from 'react';
import StatisticsApi from "../../utils/StatisticsApi"
import { Grid, Paper, Typography, IconButton, Button, Collapse } from '@material-ui/core';
import TooltipMui from '@material-ui/core/Tooltip';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Line, PieChart, Pie, Cell, Bar, ReferenceLine } from 'recharts';
import { CLOSED_INTERVIEW_STATUSSES, capitalize, dateAsString, displayCash, displayPosition, getDiffInDays, getInterviewTypeColor, getPeriodText, getRandom, showDateTimeFromString } from '../../utils/utils';
import InterviewsDialog from './personalJourney/InterviewsDialog';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Skeleton } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import FeedbackIcon from '@material-ui/icons/Feedback';
//import clsx from 'clsx';

const useStyles = makeStyles({
    marginBottom: {
        marginBottom: '16px !important'
    },
    metricContainer: {
        margin: '4px 8px',
        marginBottom: 16
    },
    metrics: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    metric: {
        padding: 8,
        width: 200,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between'
    },
    metricTextsContainer: {
        display: 'flex',
        //alignItems: 'center'
    },
    metricInfo:{
        marginLeft: 4,
        color: '#ababab',
        height: 16,
        width: 16
    },
    toolTip:{
        fontSize:14
    },
    regularText:{
        fontSize:14
    },
    biggerText:{
        fontSize:16
    }
});

/*const FullHeightBar = (props) => {
    const { x, y, width, height, value, fill } = props;
  
    const barHeight = value === 1 ? '100%' : height;
  
    return (
      <rect x={x} y={y} width={width} height={barHeight} fill={fill} />
    );
};*/

function Kpi(props){
    const title = props.title
    const data = props.data
    const benchmark = props.benchmark
    const extraText = props.extraText
    const belowData = props.belowData
    const toolTip = props.toolTip

    const classes = useStyles();
    return (
        <Paper elevation={3} className={classes.metric}>
            <div className={classes.metricTextsContainer}>
                <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                    <div>
                        {title&&<Typography className={classes.regularText} variant='subtitle2' style={{fontWeight:'bold', marginBottom:4}}>{capitalize(title)}</Typography>}
                    </div>
                    <div>
                        {(data!==undefined)&&<Typography className={classes.regularText} variant='subtitle1' style={{fontWeight:'bold'}}>{props.ratio&&<>1<strong> : </strong></>}{data}</Typography>}
                        {belowData&&<Typography className={classes.regularText} variant='subtitle2' style={{fontWeight:'bold', marginBottom:4}}>{belowData}</Typography>}
                        {(benchmark!==undefined)&&<Typography className={classes.regularText} variant='caption' color='textSecondary'>Benchmark {props.ratio&&<>1<strong> : </strong></>}{benchmark}</Typography>}
                        {extraText&&<Typography className={classes.regularText} variant='caption' style={{color:props.extraTextColor||'rgba(0, 0, 0, 0.54)'}} color='textSecondary'>{extraText}</Typography>}
                    </div>
                </div>
            </div>
            {toolTip&&<TooltipMui title={toolTip} aria-label={toolTip} classes={{tooltip:classes.toolTip}}>
                <InfoIcon fontSize='small' color='disabled' className={classes.metricInfo}/>
            </TooltipMui>}
        </Paper>
    )
}

function Title(props){
    const title = props.title || ''
    const toolTip = props.toolTip

    const classes = useStyles();
    return (
        <Typography style={{padding:8, margin:8, color:'black'}} variant='h6' /*color='primary'*/>
            <strong className={classes.biggerText}>{title}</strong>
            {toolTip&&<TooltipMui title={toolTip} aria-label={toolTip} classes={{tooltip:classes.toolTip}}>
                <InfoIcon fontSize='small' color='disabled' className={classes.metricInfo}/>
            </TooltipMui>}
        </Typography>
    )
}

function InterviewProcess(props){
    const interviewsGroup = props.interviewsGroup
    const handleEditStatus = props.handleEditStatus
    const handleNewInterviewInProccess = props.handleNewInterviewInProccess

    const interviews = interviewsGroup.interviews || [{}]
    const totalDays = Math.round(getDiffInDays(interviews[0].interviewDate))

    const classes = useStyles();
    return (
        <Fragment>
            {(!CLOSED_INTERVIEW_STATUSSES.includes(interviews[0].status))&&
            <Paper elevation={3} style={{padding:8, margin:8, marginTop: 0, overflow:'auto'}} className={classes.marginBottom}>
                <Typography className={classes.regularText} component="div"><strong>{showDateTimeFromString(interviewsGroup.date, false)} </strong><Typography className={classes.regularText} variant='h6' style={{display:'inline-block'}}><strong>{capitalize(interviewsGroup._id)}</strong></Typography> {interviews[0].title}</Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                {interviews.map((interview, index) => {
                    const nextInterview = interviews[index+1] || {}
                    const color = getInterviewTypeColor(interview.type)
                    const days = Math.round(getDiffInDays(interview.interviewDate, nextInterview.interviewDate))
                    /*let length = days

                    if (days > 15){
                        length = 15
                    }

                    if (days === 0){
                        length = 1
                    }*/
                    
                    return (
                        <div key={interview.interviewDate + index} style={{textAlign:'center', marginRight:20}}>
                            <div style={{height:20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {/*(days>20)?
                                    <div style={{color, fontWeight:900}}>{'< '+days+' days >'}</div>:
                                    <TooltipMui title={days+' days'} aria-label="add">
                                        <div style={{backgroundColor:color, height: 8, width: 10*days,borderRadius: '50px', margin:"0 auto"}}></div>
                                    </TooltipMui>
                                */}
                                <TooltipMui title={days+' days'} aria-label="add">
                                    {/*<div style={{backgroundColor:color, height: 8, width: 10*length,borderRadius: '50px', margin:"0 auto"}}></div>*/}
                                    <ArrowRightAltIcon style={{color:color}} fontSize='small'/>
                                </TooltipMui>
                            </div>
                            <div style={{fontSize:11}}>{interview.type}</div>
                                {/* - {interview.interviewDate} - {nextInterview.interviewDate} ({days})*/}
                            </div>
                    )})}
                    <Typography className={classes.regularText} component="span" color='textSecondary' style={{marginRight:8, lineHeight:'1'}}> {totalDays} days</Typography>
                    <div style={{flex: '1 1 0%'}}></div>
                    <Typography className={classes.regularText} component="span"><strong>{interviews[0].status}</strong></Typography>
                    <IconButton aria-label="edit" onClick={handleEditStatus(interviews[0])}>  
                        <EditIcon fontSize="small"/>
                    </IconButton>
                    <IconButton aria-label="add" onClick={handleNewInterviewInProccess(interviews[interviews.length-1])}>
                        <AddCircleOutlineIcon fontSize="small"/>
                    </IconButton>
                </div>
            </Paper>}
        </Fragment>
    )
}

function TitleLoading(props){
    return (
        <Typography variant="h1" style={{padding:8, margin:8}}>
            <Skeleton variant="text" width={200} height={32} animation="wave" />
        </Typography>
    )
}

function InterviewProcessLoading(props){
    return (
        <Paper elevation={3} style={{padding:8, margin:8}}> 
            <Typography component="div"><Skeleton variant="text" height={28} width={getRandom(200, 250)}  animation="wave" /></Typography>
            <div style={{display: 'flex',alignContent: 'center'}}>
                {[...Array(getRandom(3, 6))].map((x, i) => (
                    <div key={i} style={{marginRight:5}}>
                        <Skeleton variant="rect" width={getRandom(60, 100)} height={26} animation="wave"/>
                    </div>
                ))}
                <div style={{flex: '1 1 0%'}}></div>
                <div style={{marginRight:5}}>
                    <Skeleton variant="rect" width={getRandom(60, 100)} height={26} animation="wave"/>
                </div>
                <div style={{marginRight:5}}>
                    <Skeleton variant="circle" width={24} height={24} animation="wave"/>
                </div>
                <div style={{marginRight:5}}>
                    <Skeleton variant="circle" width={24} height={24} animation="wave"/>
                </div>
            </div>
        </Paper>
    )
}

function MetricsLoading(props){
    const classes = useStyles();

    return (
        <div className={classes.metrics}>
            {[...Array(props.count||5)].map((x, i) => (
            <div key={i} className={classes.metricContainer}>
                <Paper elevation={3} className={classes.metric}>
                    <div>
                        <Typography variant='subtitle2' style={{fontWeight:'bold', marginBottom:4}}>
                            <Skeleton variant="text" height={18} width={getRandom(140, 16)}  animation="wave" />
                        </Typography>
                        <Typography variant='subtitle1' style={{fontWeight:'bold'}}>
                            <Skeleton variant="text" height={19} width={getRandom(80, 100)}  animation="wave" />
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <Skeleton variant="text" height={17} width={getRandom(100, 110)}  animation="wave" />
                        </Typography>
                    </div>
                </Paper>
            </div>
            ))}
        </div>
    )
}

export default function Kpis(props) {
    const [isLoadingAll, setIsLoadingAll] = useState(true);
    const [numberOfInterviewsData, setNumberOfInterviewsData] = useState();
    const [chartData, setChartData] = useState({});
    const [approachesByTypes, setApproachesByTypes] = useState();
    const [interviewsByTypes, setInterviewsByTypes] = useState();
    const [data, setData] = useState({ratioOfInterviewsToJobOffers: {}, ratioOfInterviewsToJobOffersBenchmark: {}, signed: {}, frozen: {}});
    const [perOfferSalaryDifference, setPerOfferSalaryDifference] = useState([]);
    const [interviewsGroups, setInterviewsGroups] = useState([]);
    const [journeyData, setJourneyData] = useState({});
    const [talentEngagementsData, setTalentEngagementsData] = useState();
    const [totalProcesses, setTotalProcesses] = useState();
    const [showAllProccesses, setShowAllProccesses] = useState(false);
    const [publications, setPublications] = useState([]);

    const [objectToEdit, setObjectToEdit] = useState();
    const [mainProcceesAnimation, setMainProcceesAnimation] = useState(false);
    const [totalProcceesAnimation, setTotalProcceesAnimation] = useState(false);

    //const searchParams = new URLSearchParams(window.location.search);
    //const userId = searchParams.get("userId")

    const isAdmin = props.isAdmin || false
    const userId = props.userId

    const today = new Date().toISOString().substring(0, 10)

    useEffect(() => {
        const paramsToPass = {}
        if (isAdmin){
            paramsToPass.userId = userId
        }

        StatisticsApi.getKpisData(paramsToPass,(response)=>{
            const from = response.from
            const to = response.to
            const graphType = "weekly"

            let newdata = []
            let newChartData = {min:0, max:0}

            const mappedPublications = ((response.publications || []).map((row, index) => (
                {...row, position: displayPosition(index+1)}
            )))

            if (from <= to){
                let currDate = new Date(from)

                currDate.setHours(13)
                if (graphType === 'weekly'){
                    currDate.setDate(currDate.getDate() - currDate.getDay())
                } else if (graphType === 'monthly'){
                    currDate.setDate(1)
                }

                const toDate = new Date(to)
                
                while (currDate <= toDate){
                    let currDateStr
                    switch(graphType){
                        case 'dailey':{
                            currDateStr = currDate.getDate() + '.' + (currDate.getMonth() + 1)
                            break;
                        } case 'weekly':{
                            currDateStr = currDate.getDate() + '.' + (currDate.getMonth() + 1) + ' W'
                            break;
                        } case 'monthly':{
                            currDateStr = currDate.getFullYear() + '-' + (currDate.getMonth() + 1)
                            break;
                        } default: {
                            currDateStr = ''
                        }
                    }

                    let row = {Date:currDateStr}
                    //let row = {Date:+currDate}

                    const numberOfInterviews = response.numberOfInterviews || []
                    const params = ["Benchmark", "Interviews"]
                    params.forEach(param => {
                        const interviewFounded = (numberOfInterviews[param]||[]).find(object => {
                            switch(graphType){
                                case 'dailey':{
                                    return object._id.year === currDate.getFullYear() && 
                                        object._id.month === (currDate.getMonth() + 1) &&
                                        object._id.day === (currDate.getDate())
                                } case 'weekly':{
                                    return !object._id.isApproach && currDate.toISOString().substring(0, 10) === object._id.firstDayOfWeek.substring(0, 10)
                                } case 'monthly':{
                                    return object._id.year === currDate.getFullYear() && object._id.month === (currDate.getMonth() + 1)
                                } default: {
                                    return false
                                }
                            }
                        });  

                        const fieldName = 'Interviews' + (param === 'Benchmark' ? ' Benchmark' : '')
                        if (interviewFounded){
                            const value = +parseFloat(interviewFounded.total || 0).toFixed(2)
                            row[fieldName] = value
                            if (value > newChartData.max){
                                newChartData.max = value
                            }
                            if (value < newChartData.min){
                                newChartData.min = value
                            }
                        } else {
                            row[fieldName] = 0
                        }

                        const approachFounded = (numberOfInterviews[param]||[]).find(object => {
                            switch(graphType){
                                case 'dailey':{
                                    return object._id.year === currDate.getFullYear() && 
                                        object._id.month === (currDate.getMonth() + 1) &&
                                        object._id.day === (currDate.getDate())
                                } case 'weekly':{
                                    return object._id.isApproach && currDate.toISOString().substring(0, 10) === object._id.firstDayOfWeek.substring(0, 10)
                                } case 'monthly':{
                                    return object._id.year === currDate.getFullYear() && object._id.month === (currDate.getMonth() + 1)
                                } default: {
                                    return false
                                }
                            }
                        });  

                        const fieldName2 = 'Approaches' + (param === 'Benchmark' ? ' Benchmark' : '')
                        if (approachFounded){
                            const value = +parseFloat(approachFounded.total || 0).toFixed(2)
                            row[fieldName2] = value
                            if (value > newChartData.max){
                                newChartData.max = value
                            }
                            if (value < newChartData.min){
                                newChartData.min = value
                            }
                        } else {
                            row[fieldName2] = 0
                        }
                    });

                    const objectFounded = (mappedPublications||[]).find(object => {
                        switch(graphType){
                            case 'dailey':{
                                return object._id.year === currDate.getFullYear() && 
                                    object._id.month === (currDate.getMonth() + 1) &&
                                    object._id.day === (currDate.getDate())
                            } case 'weekly':{
                                return currDate.toISOString().substring(0, 10) === object.week.substring(0, 10)
                            } case 'monthly':{
                                return object._id.year === currDate.getFullYear() && object._id.month === (currDate.getMonth() + 1)
                            } default: {
                                return false
                            }
                        }
                    });  

                    if (objectFounded){
                        row.PublicationData = {firstSent: objectFounded.firstSent, position: objectFounded.position}
                    }

                    newdata.push(row)
                    
                    switch(graphType){
                        case 'dailey':{
                            currDate.setDate(currDate.getDate()+1)
                            break;
                        } case 'weekly':{
                            currDate.setDate(currDate.getDate()+7)
                            break;
                        } case 'monthly':{
                            currDate.setMonth(currDate.getMonth()+1)
                            break;
                        } default: {
                            currDateStr = ''
                        }
                    }
                }
            }
            setChartData(newChartData)
            setNumberOfInterviewsData(newdata)

            newdata = []
            const graphType2 = 'dailey'
            if (from <= to){
                let currDate = new Date(from)

                currDate.setHours(13)
                if (graphType2 === 'weekly'){
                    currDate.setDate(currDate.getDate() - currDate.getDay())
                } else if (graphType2 === 'monthly'){
                    currDate.setDate(1)
                }

                const toDate = new Date(to)
                const talentEngagements = response.talentEngagements || []
                
                while (currDate <= toDate){
                    let currDateStr
                    switch(graphType2){
                        case 'dailey':{
                            currDateStr = currDate.getDate() + '.' + (currDate.getMonth() + 1)
                            break;
                        } case 'weekly':{
                            currDateStr = currDate.getDate() + '.' + (currDate.getMonth() + 1) + ' W'
                            break;
                        } case 'monthly':{
                            currDateStr = currDate.getFullYear() + '-' + (currDate.getMonth() + 1)
                            break;
                        } default: {
                            currDateStr = ''
                        }
                    }

                    let row = {Date:currDateStr}
                    //let row = {Date:+currDate}

                    const talentEngagementFounded = talentEngagements.find(object => {
                        return currDate.toISOString().substring(0, 10) === object.date.substring(0, 10)
                    });  

                    const fieldName = 'Engagement'
                    if (talentEngagementFounded){
                        row[fieldName] = +parseFloat(talentEngagementFounded.score || 0).toFixed(2)
                    } else {
                        row[fieldName] = 0
                    }

                    newdata.push(row)
                    
                    switch(graphType2){
                        case 'dailey':{
                            currDate.setDate(currDate.getDate()+1)
                            break;
                        } case 'weekly':{
                            currDate.setDate(currDate.getDate()+7)
                            break;
                        } case 'monthly':{
                            currDate.setMonth(currDate.getMonth()+1)
                            break;
                        } default: {
                            currDateStr = ''
                        }
                    }
                }
            }
            setTalentEngagementsData(newdata || [])

            const APPROACHES_TYPES = [
                `Self Application`,
                `Recruiter Call (Introduction)`,
                `Recruiter Call (Follow up)`,
                `Self Submitted Application`,
                `Incoming Email (First contact)`,
                `Phone call with a recruiter`,
                `Self Submitted Application`,
                `Recruiter Call`,
                `Linkedin Approach`,
                `WhatsApp Approach`
            ]

            const mppedInterviews = (response.interviewsBreakdownByType||[]).map((row) => ({
                name: row._id, 
                value: row.total 
            }))

            setApproachesByTypes(mppedInterviews.filter(mppedInterview => APPROACHES_TYPES.includes(mppedInterview.name)))

            setInterviewsByTypes(mppedInterviews.filter(mppedInterview => !APPROACHES_TYPES.includes(mppedInterview.name)))

            const openProcceses = (response.interviewsGroups || []).filter(interviewsGroup => 
                interviewsGroup.interviews && !CLOSED_INTERVIEW_STATUSSES.includes(interviewsGroup.interviews[0].status)
            )

            setTotalProcesses(openProcceses.length)
            setInterviewsGroups(openProcceses.filter(interviewsGroup => interviewsGroup.interviews && interviewsGroup.interviews.length>1))

            setPerOfferSalaryDifference(response.perOfferSalaryDifference || [])

            setJourneyData(response.journeyData || {})

            let signed = {}
            let frozen = {}
            response.daysInEachJourneyStatus.forEach(status => {
                const benchmarkStatusData = response.daysInEachJourneyStatusBenchmark.find(benchMarkStatusData => benchMarkStatusData._id === status._id) || {}
                
                switch (status._id){
                    case 'signed':{
                        signed = {days:status.days, benchmark: benchmarkStatusData.days}
                        break;
                    } case 'freezed': {
                        frozen = {days:status.days, benchmark: benchmarkStatusData.days}
                        break;
                    } default: {}
                }
            });

            let lastPublicationSent = response.beginServiceDate
            
            if (response.publications && response.publications.length>0){
                const sentPublications = response.publications.filter(publication => publication.firstSent)
                lastPublicationSent = sentPublications[sentPublications.length-1].firstSent
            }

            setPublications((mappedPublications || []).map((row, index) => ({
                position: row.position, 
                week: new Date(row.week).getDate() + '.' + (new Date(row.week).getMonth() + 1) + ' W'
            })))

            setData({
                numberOfPublications: response.numberOfPublications,
                beginServiceDate: response.beginServiceDate,
                ratiOfPublicationsToInterviews: response.ratiOfPublicationsToInterviews,
                ratiOfPublicationsToInterviewsBenchmark: response.ratiOfPublicationsToInterviewsBenchmark,
                ratioOfInterviewsToJobOffers: response.ratioOfInterviewsToJobOffers,
                ratioOfInterviewsToJobOffersBenchmark: response.ratioOfInterviewsToJobOffersBenchmark,
                avgTimeToOffer: response.avgTimeToOffer,
                avgTimeToOfferBenchmark: response.avgTimeToOfferBenchmark,
                rationSelfSubmitedToInterviews: response.rationSelfSubmitedToInterviews,
                rationSelfSubmitedToInterviewsBenchmark: response.rationSelfSubmitedToInterviewsBenchmark,
                offersDifferenceAvg: response.offersDifferenceAvg,
                offersDiffsPercentageAvg: response.offersDiffsPercentageAvg,
                /*timeToFirstPublication: response.timeToFirstPublication,
                timeToFirstPublicationBenchmark: response.timeToFirstPublicationBenchmark,*/
                timeBetweenPublicationsBenchmark: response.timeBetweenPublicationsBenchmark,
                signed, frozen, lastPublicationSent
            })

            setIsLoadingAll(false)

            //console.log(response)
        }, (error) => {
            console.log(error)
            setIsLoadingAll(false)
        })
    }, [isAdmin, userId]);

    const startEditInterviews = () => {
        //setIsLoadingInterviews(true)
    }

    const finishEditInterviews = (response) => {
        const openProcceses = (response || []).filter(interviewsGroup => 
            interviewsGroup.interviews && !CLOSED_INTERVIEW_STATUSSES.includes(interviewsGroup.interviews[0].status)
        )

        const newMainProcceses = openProcceses.filter(interviewsGroup => interviewsGroup.interviews && interviewsGroup.interviews.length>1)

        let mainProccesesCountChanged = (newMainProcceses.length !== interviewsGroups.length)
        let totalProccesesCountChanged = (totalProcesses !== openProcceses.length)

        setTotalProcesses(openProcceses.length)
        setInterviewsGroups(newMainProcceses)

        if (mainProccesesCountChanged){
            setMainProcceesAnimation(false);
            // Wait for a brief moment to re-add the class
            setTimeout(() => {
                setMainProcceesAnimation(true);
            }, 0);
        }

        if (totalProccesesCountChanged){
        setTotalProcceesAnimation(false);
            // Wait for a brief moment to re-add the class
            setTimeout(() => {
                setTotalProcceesAnimation(true);
            }, 0);
        }
    }

    const propsToChildren = {
        journeyData,
        /*isAdmin,
        userId,
        disabled,*/
        startEditInterviews,
        finishEditInterviews,
        setObjectToEdit,
    }

    const handleNewInterview = () => {
        setObjectToEdit({
            interviewDate:today,
            type:"HR Interview",
            status:"Interviewing",
            seniorityLevel: journeyData.seniorityLevel||'',
        })
    }

    const handleNewInterviewInProccess = interview => () => {
        setObjectToEdit({
            company: interview.company,
            companyUrl: interview.companyUrl,
            location: interview.location,
            status: interview.status,
            interviewerName: interview.interviewerName,
            interviewerEmail: interview.interviewerEmail,
            interviewerPhone: interview.interviewerPhone,
            type:"HR Interview",
            seniorityLevel: interview.seniorityLevel,
            interviewDate:today,
        })
    }

    const handleEditStatus = interview => () => {
        setObjectToEdit({...interview, fieldsType:'status'/*, offer:props.offer*/})
    }

    const showNumber = (number) => {
        if (!number){
            return ''
        }

        return +parseFloat(number).toFixed(2)
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let publicationData
            if (payload && payload[0] && payload[0].payload && payload[0].payload.PublicationData && payload[0].payload.PublicationData.firstSent){
                publicationData = payload[0].payload.PublicationData
            }

            return (

                <Paper elevation={3} style={{padding:8, margin:8}}> 
                    <Typography variant='subtitle1'><strong>{label}</strong></Typography>
                    {publicationData&&<Typography style={{color: '#aa4dc8'}} variant='subtitle2'><strong>{publicationData.position} Publication:</strong> {showDateTimeFromString(publicationData.firstSent, false)}</Typography>}
                    {payload.map((item) => (
                        <Typography style={{color:item.color}} key={item.dataKey}><strong>{item.dataKey}:</strong> {item.value}</Typography>
                    ))}
                </Paper>
            );
        }
      
        return null;
    };

    const CustomPieTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && payload[0]) {
            const item = payload[0]

            return (
                <Paper elevation={3} style={{padding:8, margin:8}}> 
                    <Typography><strong>{item.name}:</strong> {item.value}</Typography>
                </Paper>
            );
        }
      
        return null;
    };

    const CustomizedYAxisTick = ({ x, y, payload, max, min }) => {
        const { value } = payload;
        if (value === max || value === min) {
            return (
                <text x={x - 5} y={y + 3} dy={0} textAnchor="end" fontSize={12} fill="#666">
                    {value}
                </text>
            );
        }
        return null;
    };

    const showFeedback = () => {
        if (props.showFeedback){
            props.showFeedback()
        }
    }

    const metrics = [
        {
            title:'Plan: ' + journeyData.plan||'', 
            data:getPeriodText({date:data.beginServiceDate,toWeeks:true}) + ' in Service', 
            extraText:'Started: ' + dateAsString(new Date(data.beginServiceDate), false),
            belowData: (data.frozen.days)?('Account frozen: ' + showNumber(data.frozen.days) + ' days'):'',
        },
        {
            title:'publications', 
            data:data.numberOfPublications,
            extraText:'Last Approved: ' + dateAsString(new Date(journeyData.publishApproveDate), false),
            //toolTip: `The number of publication cycles (two week periods; we publish our Talent’s CVs to our contact network roughly every 2 week periods per these cycles) and number of actual publications that we published your CV to our contact network once it was ready and approved.`
        },
        {title:'Time between Publication', data:getPeriodText({date:data.lastPublicationSent,toWeeks:true}), benchmark:showNumber(data.timeBetweenPublicationsBenchmark) + ' days'},
        //{hide: (data.signed.days ===undefined), title:'signed', data:showNumber(data.signed.days) + ' days', benchmark:showNumber(data.signed.benchmark) + ' days'},
        {
            title:'publications to interviews ratio', 
            data:showNumber(data.ratiOfPublicationsToInterviews), 
            benchmark:showNumber(data.ratiOfPublicationsToInterviewsBenchmark), ratio:true,
            toolTip: `How many publications to our contact network, on average – across all your hiring processes – does it take to get an interview. The benchmarks is averaged across all Jobhunt customers.`
        },
        {
            title:'interviews to approaches ratio', 
            data:showNumber(data.rationSelfSubmitedToInterviews), 
            benchmark:showNumber(data.rationSelfSubmitedToInterviewsBenchmark), ratio:true,
            toolTip: `How many approaches you’ve recorded for every interview you’ve had. Proactive jobhunters that apply themselves independently see the most and best results. Recruiters are more likely to reply when they receive your CV from more than one source – such as your application plus our publishing. The benchmarks is averaged across all Jobhunt customers.`
        },
    ]

    const offersMetrics = [
        /*{
            title:'avg salary change', 
            data: (((data.offersDifferenceAvg>0)?'+':'')+data.offersDifferenceAvg + ' (' + showNumber(data.offersDiffsPercentageAvg)+'%)'),
            toolTip: `The average difference between your current offers and your previous salary (per your onboarding “dream job” questionnaire)`
        },*/
        {title:'Time To 1st Offer', data:showNumber(data.avgTimeToOffer) + ' days', benchmark:showNumber(data.avgTimeToOfferBenchmark) + ' days'},
        /*{
            title:'offers to interviews ratio', 
            data:showNumber(data.ratioOfInterviewsToJobOffers), 
            benchmark:showNumber(data.ratioOfInterviewsToJobOffersBenchmark), ratio:true,
            toolTip: `How many interviews on average – across all your hiring processes – does it take to receive a job offer. The benchmarks is averaged across all JobHunt customers.`
        }*/
    ]

    const windowWidth = window.innerWidth
    const rightSideWidth = Math.floor(windowWidth / 2 / 220)*220
    const isDesktop = (window.innerWidth > 768)

    const classes = useStyles();

    return (
        <div className="fullWidthContainer" style={{backgroundColor: 'white', overflowY: 'auto', overflowX: 'hidden'}}>

            <InterviewsDialog {...propsToChildren} objectToEdit={objectToEdit} interviewsSort={-1} fetchInterviewGroups/>

            {(!isLoadingAll&&props.showFeedback)&&<div style={{right: 0,position: 'absolute',top: 0}}>
                <Button onClick={showFeedback} size="small" style={{color: '#757575'}}>
                    <FeedbackIcon fontSize='small'/>
                    <span style={{textTransform: 'capitalize', marginLeft:4}}>Submit Feedback</span>
                </Button>
            </div>}

            <Grid container spacing={1}>
                <Grid item sm={6} xs={12} style={isDesktop?{maxWidth:windowWidth-rightSideWidth-9, flexBasis:windowWidth-rightSideWidth-9}:{}}>
                    {isLoadingAll?<>
                        <TitleLoading/>
                        <Paper elevation={3} style={{padding:8, margin:8}}> 
                            <Skeleton variant="rect" width={"100%"} height={215} animation="wave"/>
                        </Paper>
                        <TitleLoading/>
                        <InterviewProcessLoading/>
                        <InterviewProcessLoading/>
                    </>:<>
                {numberOfInterviewsData&&<>
                    <Title title="My Journey"/>
                    <Paper elevation={3} style={{padding:8, margin:8, height:225}} className={classes.marginBottom}>
                        <div style={{height:'80%', width:'100%', paddingTop:16}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    width={500}
                                    height={250}
                                    data={numberOfInterviewsData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: -20,
                                        bottom: 5
                                    }}
                                >
                                    {/*<CartesianGrid strokeDasharray="3 3" horizontal={false} />*/}
                                    <XAxis dataKey="Date" />
                                    <YAxis axisLine={false}  tickLine={false}
                                        domain={[chartData.min, chartData.max]}
                                        ticks={[chartData.min, chartData.max]}
                                        tick={<CustomizedYAxisTick max={chartData.max} min={chartData.min} />}
                                    />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend  wrapperStyle={{ left: 0, fontSize:14 }} /*formatter={renderLegendText}*/ />
                                    <Line type="monotone" dot={false} dataKey={"Interviews"} stroke={"#0055bc"} />
                                    <Line type="monotone" dot={false} dataKey={"Interviews Benchmark"} stroke={"#00a1c2"} />
                                    <Bar dataKey={"Approaches"} fill={"#ed6804"}/>
                                    <Bar dataKey={"Publication"} fill={"#9966ff"} /*shape={<FullHeightBar/>}*//>
                                    {publications.map((publication) => (
                                        <ReferenceLine 
                                            key={publication.position} x={publication.week} 
                                            stroke={"#9966ff"} 
                                            //label={publication.position}
                                        />
                                    ))}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </>}
                <div>
                    <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',padding: 8}}>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <Typography component="div" style={{color:'black'}} /*color='primary'*/ variant='h6'>
                                <strong className={classes.biggerText}>Main Processes <span style={{display:'inline-block'}} className={`animated-element ${mainProcceesAnimation ? 'active' : ''}`}>({interviewsGroups.length})</span></strong>
                            </Typography>
                            <Typography component="div" /*color='primary'*/ variant='h6' style={{marginLeft: 8, color:'black'}}>
                                <span className={classes.regularText}>Total Processes <span style={{display:'inline-block'}} className={`animated-element ${totalProcceesAnimation ? 'active' : ''}`}>({totalProcesses})</span></span>
                            </Typography>
                        </div>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={handleNewInterview}
                                size='small'
                            >Add Interview</Button>
                        </div>
                    </div>
                    {interviewsGroups.slice(0,3).map((interviewsGroup) => 
                        <InterviewProcess  key={interviewsGroup._id} interviewsGroup={interviewsGroup} handleEditStatus={handleEditStatus} handleNewInterviewInProccess={handleNewInterviewInProccess}/>
                    )}
                    {(interviewsGroups.length>3)&&<>
                        {!showAllProccesses&&<div style={{textAlign:'center'}}><Button color="primary" onClick={()=>{setShowAllProccesses(true)}}>Show More</Button></div>}
                        <Collapse in={showAllProccesses} timeout="auto" unmountOnExit>
                            {interviewsGroups.slice(3).map((interviewsGroup) => 
                                <InterviewProcess  key={interviewsGroup._id} interviewsGroup={interviewsGroup} handleEditStatus={handleEditStatus} handleNewInterviewInProccess={handleNewInterviewInProccess}/>
                            )}
                        </Collapse>
                    </>}
                </div>
                {talentEngagementsData&&false&&<Paper elevation={3} style={{padding:8, margin:8, height:250}}>
                    <div style={{height:'80%', width:'100%', paddingTop:16}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart

                                width={500}
                                height={250}
                                data={talentEngagementsData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Date" />

                                <YAxis />
                                <Tooltip />
                                <Legend /*formatter={renderLegendText}*/ />
                                {/*<defs>
                                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset={3} stopColor="green" stopOpacity={1} />
                                    <stop offset={3} stopColor="red" stopOpacity={1} />
                                    </linearGradient>
                                </defs>*/}
                                <Line /*type="monotone"*/ dataKey={"Engagement"} stroke="#000" /*fill={`url(#colorUv)`}*/ /*fill="url(#splitColor)"*/ /*baseValue={3}*/ /*fill={COLORS[1].dark}*/ />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </Paper>}
                </>}
                </Grid>

                <Grid item sm={6} xs={12}  style={isDesktop?{maxWidth:rightSideWidth, flexBasis:rightSideWidth}:{}}>
                    {isLoadingAll?<>
                        <TitleLoading/>
                        <MetricsLoading count={5}/>
                        <TitleLoading/>
                        <Paper elevation={3} style={{padding:8, margin:8, display: 'flex'}} className={classes.marginBottom}> 
                            <div>
                                {[...Array(7)].map((x, i) => (
                                    <div key={i}>
                                        <Skeleton variant="text" height={20} width={getRandom(100, 250)} animation="wave"/>
                                    </div>
                                ))}
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 50}}>
                                    <Skeleton variant="circle" height={100} width={100} animation="wave"/>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginLeft: 50}}>
                                    <Skeleton variant="circle" height={100} width={100} animation="wave"/>
                                </div>
                            </div>
                        </Paper>
                    </>:<>
                    <Title title="Service Data"/>
                    <div className={classes.metrics}>
                        {metrics.map((metric) => (
                            <Fragment key={metric.title}>
                                {!metric.hide&&<div className={classes.metricContainer}>
                                    <Kpi {...metric}/>
                                </div>}
                            </Fragment>
                        ))}
                    </div>

                    {approachesByTypes&&interviewsByTypes&&(approachesByTypes.length>0||interviewsByTypes.length>0)&&<>
                        <Title title="My Interviews"/>
                    
                        <Paper elevation={1} style={{padding:8, margin:8, display: 'flex'}} className={classes.marginBottom}>
                            <div>
                                {(approachesByTypes.length>0)&&<Typography className={classes.regularText}><strong>Approaches</strong></Typography>}
                                {approachesByTypes.map((entry, index) => (
                                    <div key={`cell-${index}`} className={classes.regularText} style={{display: 'flex', alignItems: 'center', minWidth: 250}}>
                                        <i style={{backgroundColor:getInterviewTypeColor(entry.name), width: 15,display: 'inline-block',height: 10,marginRight: 4}}></i> {entry.name}
                                    </div>
                                ))}
                                {(interviewsByTypes.length>0)&&<Typography className={classes.regularText}><strong>Interviews</strong></Typography>}
                                {interviewsByTypes.map((entry, index) => (
                                    <div key={`cell-${index}`} className={classes.regularText} style={{display: 'flex', alignItems: 'center', minWidth: 250}}>
                                        <i style={{backgroundColor:getInterviewTypeColor(entry.name), width: 15,display: 'inline-block',height: 10,marginRight: 4}}></i> {entry.name}
                                    </div>
                                ))}
                            </div>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {(approachesByTypes.length>0)&&<div>
                                <Typography className={classes.regularText} style={{textAlign:'center'}}><strong>Approaches</strong></Typography>
                                <PieChart width={160} height={160}>
                                    <Pie
                                        data={approachesByTypes}
                                        cx="50%"
                                        cy="50%"
                                        label
                                        outerRadius={50}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {approachesByTypes.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={getInterviewTypeColor(entry.name)}/>
                                        ))}
                                        
                                    </Pie>
                                    <Tooltip content={<CustomPieTooltip />} />
                                </PieChart>
                            </div>}
                            {(interviewsByTypes.length>0)&&<div>
                                <Typography className={classes.regularText} style={{textAlign:'center'}}><strong>Interviews</strong></Typography>
                                <PieChart width={160} height={160}>
                                    <Pie
                                        data={interviewsByTypes}
                                        cx="50%"
                                        cy="50%"
                                        label
                                        outerRadius={50}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {interviewsByTypes.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={getInterviewTypeColor(entry.name)}/>
                                        ))}
                                        
                                    </Pie>
                                    <Tooltip content={<CustomPieTooltip />} />
                                </PieChart>
                            </div>}
                            </div>
                        </Paper>
                    </>}
                    {(perOfferSalaryDifference&&perOfferSalaryDifference.length>0)&&<>
                        <Title title="My Offers" toolTip={`Each offer displays the salary your recorded for this offer, with a % comparison to your previous salary/income you provided in your onboarding/dreamjob questionnaire`}/>
                        
                        <div className={classes.metrics}>
                            {offersMetrics.map((metric) => (
                                <Fragment key={metric.title}>
                                    {!metric.hide&&<div className={classes.metricContainer}>
                                        <Kpi {...metric}/>
                                    </div>}
                                </Fragment>
                            ))}
                            {perOfferSalaryDifference.map((row) => (
                                <div key={row.company} className={classes.metricContainer}>
                                    <Kpi 
                                        title={row.company} 
                                        data={displayCash({value:row.offer, currency:row.offerCur, frequency:row.offerFreq})}
                                        extraText={((row.difference>0)?'+':'')+showNumber(row.percentage)+'%'}
                                        extraTextColor={(row.difference>0)?'green':''}
                                    />
                                </div> 
                            ))}
                        </div>
                    </>}
                    </>}
                </Grid>
            </Grid>
        </div>
    )
}