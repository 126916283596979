import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import { Fields } from '../Fields';
import { FIELD_TYPES, WEEK_DAYS, showDateTimeFromString } from '../../utils/utils';
import { Autocomplete } from '@material-ui/lab';
import MarketingPostsDialog from './MarketingPostsDialog';

export default function MarketingEvent(props) {
    const objectToEdit = props.objectToEdit
    const isPlan = props.isPlan || false
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [savedObjectData, setSavedObjectData] = useState();
    const [newData, setNewData] = useState({});
    const [mode, setMode] = useState();
    const [disabled, setDisabled] = useState(false);

    const [searchPost, setSearchPost] = useState('');
    const [openAutoComplete, setOpenAutoComplete] = useState(false);
    const [options, setOptions] = useState([]);
    const [loadPosts, setLoadPosts] = useState(false);
    const [selectedPost, setSelectedPost] = useState({});
    const [inputPost, setInputPost] = useState('');

    const [textToShow, setTextToShow] = useState();
    const [objectToEditPost, setObjectToEditPost] = useState();

    useEffect(() => {
        if (objectToEdit){
            if (objectToEdit._id){
                setEditId(objectToEdit._id)
                setMode('VIEW')
                setSavedObjectData({...objectToEdit})
                setTextToShow()
            } else {
                setEditId(-1)
                setMode('EDIT')
                setNewData({...objectToEdit})
                setSavedObjectData({...objectToEdit})
                setError()
                if (objectToEdit && objectToEdit.posts && objectToEdit.posts[0]){
                    const post = objectToEdit.posts[0]
                    setInputPost(post.name)
                    setOptions([post])
                } else {
                    setInputPost('')
                    setOptions([])
                }
            }
            if (objectToEdit && objectToEdit.posts && objectToEdit.posts[0]){
                const post = objectToEdit.posts[0]
                setSelectedPost({...post})
            } else {
                setSelectedPost({})
            }
        } else {
            setEditId()
            setSelectedPost({})
        }
    }, [objectToEdit]);

    let versionObj = {}

    if (mode === 'VIEW' && selectedPost && selectedPost.versions && savedObjectData.version !== undefined){
        versionObj = selectedPost.versions[savedObjectData.version]
    }

    const handleClose = () => {
        props.setObjectToEdit()
    }

    const handleEdit = () => {
        setError()
        setMode('EDIT')
        if (selectedPost && selectedPost.name){
            setInputPost(selectedPost.name)
            setOptions([selectedPost])
        } else {
            setInputPost('')
            setOptions([])
        }
        setNewData({})
    }

    const handleEdiVersion = () => {
        setObjectToEditPost({...selectedPost})
    }

    const handleSetPost = updatedPost => {
        setSelectedPost({...updatedPost})
        props.handleRefresh()
    }

    const handleDelete = () => {
        setError()
        setNewData({status:'Closed'})
        setMode('DELETE')
    }

    const handlePublished = () => {
        let text
        
        if (versionObj.beforeCTA || versionObj.afterCTA ){
            text = `${versionObj.beforeCTA || ''}
https://jobhunt.co.il/?career&source=${savedObjectData.uniqueId}
${versionObj.afterCTA || ''}`
        } else if (savedObjectData.hebrewText || savedObjectData.englishText){
            text = `${savedObjectData.hebrewText || savedObjectData.englishText}`
        } else {
            text = `https://jobhunt.co.il/?career&source=${savedObjectData.uniqueId}`
        }
        
        navigator.clipboard.writeText(text)

        console.log(text)

        setError()
        setMode('PUBLISHED')
        setNewData({status:'Published'})
    }

    /*const copyToClipboard = async () => {
        const htmlContent = "<p style='background:none'><b>Hello</b>, world!</p>";
    
        // Create a temporary element to hold the HTML content
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlContent;
        document.body.appendChild(tempElement);
        // Select the HTML content
        const range = document.createRange();
        range.selectNode(tempElement);
        // Copy the selected HTML content to the clipboard
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        document.body.removeChild(tempElement);
    };*/

    /*const downloadAttachments = (url) => {
        const link = document.createElement("a");
        link.href = url;
        
        // Extract filename from URL
        const filename = url.split("/").pop().split("?")[0]; // Remove query params if any
        link.download = filename || "download"; // Use extracted name or default

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }*/

    const handleCancel = () => {
        if (editId === -1){
            handleClose()
        } else {
            setMode('VIEW')
            setError()
            setNewData()
            setInputPost('')
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setDisabled(true)

        AdminJHApi.createEditMEvent({newData, editId},(response)=>{
            if (mode === 'DELETE'){
                handleClose()
            } else {
                setSavedObjectData({...response})
                if (response && response.posts && response.posts[0]){
                    setSelectedPost(response.posts[0])
                }
                setMode('VIEW')
            }
            setDisabled(false)
            props.handleRefresh()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleChangeField = e => {
        if (e.target.name === 'dateTime'){
            setNewData({
                ...newData,
                [e.target.name]: e.target.value,
                date: e.target.value.substring(0,10),
                timeHours: e.target.value.substring(11,13),
                timeMinutes: e.target.value.substring(14,16)
            })
        } else {
            setNewData({...newData,[e.target.name]: e.target.value})
        }
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (savedObjectData[name] !== undefined){
            return savedObjectData[name]
        } else {
            return defaultValue
        }
    }

    const handleSelectPostOption = (event, newValue) => {
        if (newValue && newValue._id){
            setNewData({...newData,postId: newValue._id, version:newValue.defaultVersion||0})
            setInputPost(newValue.name)
            setSelectedPost(newValue)
            setOptions([newValue])
        } else {
            setNewData({...newData,postId: ''})
            setInputPost('')
            setSelectedPost({})
        }

        setSearchPost('')
    }

    const handleChangePostInput  = e => {
        if(!e || !e.target || typeof e.target.value !== 'string'){
            return
        }

        setInputPost(e.target.value)

        const firstThreeLetters = e.target.value.substring(0, 2)

        if(!loadPosts && e.target.value.length > 1 && searchPost !== firstThreeLetters){
            setSearchPost(firstThreeLetters)
            setLoadPosts(true)

            AdminJHApi.findMPosts({search:firstThreeLetters},(response)=>{
                setOptions(response);
                setLoadPosts(false)
                //setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
            }, (error) => {
                setLoadPosts(false)
            })
        } else if(e.target.value.length === 0){
            setOptions([]);
            setSearchPost('')
        }
    }

    const types = [
        {value: "Post", label: "Post"},
        {value: "Review", label: "Review"},
        {value: "Full video", label: "Full video"},
        {value: "V Teaser", label: "V Teaser"},
        {value: "H Teaser", label: "H Teaser"},
        {value: "Professional", label: "Professional"},
        {value: "Poll", label: "Poll"},
        {value: "Newsletter", label: "Newsletter"},
        {value: "Announcement", label: "Announcement"},   
        {value: "Riddle", label: "Riddle"},
    ]

    const locations = [ 
        {value: "BigPage", label: "BigPage"},
        {value: "JobHunt", label: "JobHunt"},
        {value: "JobSwipe", label: "JobSwipe"},
        {value: "HWS", label: "HWS"},
        {value: "Groups", label: "Groups"},
        {value: "Counselor", label: "Counselor"},
        {value: "Recruiter", label: "Recruiter"},
        {value: "Aneli", label: "Aneli"},
        {value: "Arcady", label: "Arcady",},
        {value: "Gal", label: "Gal"},
        {value: "Keren", label: "Keren"},
        {value: "Mina", label: "Mina"},
        {value: "Miriam", label: "Miriam"},
        {value: "Mor", label: "Mor"},
        {value: "Nevena", label: "Nevena"},
        {value: "Sagi", label: "Sagi"}
    ]

    const profiles = [ 
        {value: "Aneli", label: "Aneli"},
        {value: "Arcady", label: "Arcady",},
        {value: "Gal", label: "Gal"},
        {value: "Keren", label: "Keren"},
        {value: "Mina", label: "Mina"},
        {value: "Miriam", label: "Miriam"},
        {value: "Mor", label: "Mor"},
        {value: "Nevena", label: "Nevena"},
        {value: "Sagi", label: "Sagi"}
    ]

    const statuses = [
        {value: "No Publish", label:"No Publish"},
        {value: "Ready", label:"Ready"},
        {value: "Published", label:"Published"},
    ]

    const publishers = [
        {value: "Jana", label:"Jana"},
        {value: "Mina", label:"Mina"},
        {value: "Suzana", label:"Suzana"},
    ]

    let fields = []

    const fieldsBefore = [
        { name: 'type', label: 'Type', defaultValue: '', options:types, type:FIELD_TYPES.SELECT, sm:12}, 
    ]

    if (mode === 'PUBLISHED' && !isPlan){
        fields = [
            { name: 'postedLink', label: 'Posted Link', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
        ]
    }

    if (mode === 'EDIT'){
        if (selectedPost.versions){
            const versions = selectedPost.versions.map((version, index) => {return {value: index, label: version.name || ('Vesrion' + (index+1))}})

            fields = [
                { name: 'version', label: 'Version', defaultValue: '', options:versions, type:FIELD_TYPES.SELECT, sm:12}, 
            ]
        }

        if (isPlan){
            const weekDays = WEEK_DAYS.map((day, index)=>{return {value:index, label:day}})

            fields = [
                ...fields,
                { name: 'weekDay', label: 'Day', defaultValue: 0, options:weekDays, type:FIELD_TYPES.SELECT, sm:12}, 
                { name: 'timeHours', label: 'Hour', defaultValue: '', type:FIELD_TYPES.TEXT, sm:6, inputType:'number'}, 
                { name: 'timeMinutes', label: 'Minutes', defaultValue: '', type:FIELD_TYPES.TEXT, sm:6, inputType:'number'}, 
            ]
        } else {
            fields = [
                ...fields,
                { name: 'dateTime', label: 'Date To Post', defaultValue: '', type:FIELD_TYPES.DATE_TIME, sm:12}, 
            ]
        }

        fields = [
            ...fields,
            { name: 'publisher', label: 'Publisher', defaultValue: '', options:publishers, type:FIELD_TYPES.SELECT, sm:12}, 
            { name: 'location', label: 'Location', defaultValue: '', options:locations, type:FIELD_TYPES.SELECT, sm:12}, 
            { name: 'profile', label: 'Profile', defaultValue: '', options:profiles, type:FIELD_TYPES.SELECT, sm:12}, 
            
        ]

        if (!isPlan){
            fields = [
                ...fields,
                { name: 'status', label: 'Status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, sm:12}, 
                { name: 'postedLink', label: 'Posted Link', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
                { name: 'impressions', label: 'Impressions', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'number'}, 
                { name: 'linkToPost', label: 'Link To Post', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
                { name: 'englishText', label: 'English Text', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
                { name: 'hebrewText', label: 'Hebrew Text', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
            ]
        }
    }

    const propsToChildrenPost = {
        handleSetPost: handleSetPost,
        setObjectToEdit: setObjectToEditPost,
        objectToEdit: objectToEditPost,
        versionToEdit: (savedObjectData||{}).version
    }

    return (
        <Dialog onClose={handleClose} open={(editId!==undefined)} fullWidth maxWidth={(mode === 'DELETE')?'xs':'md'}>
            {(mode === 'EDIT' || mode === 'PUBLISHED' || mode === 'DELETE')&&<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(mode === 'PUBLISHED')&&<>
                        <Typography>Text copied to clipboard</Typography>
                        <Typography><a target='_blank' rel="noopener noreferrer" href={savedObjectData.linkToPost}>Link To Post</a></Typography>
                        {selectedPost.contentLink&&<Typography><a target='_blank' rel="noopener noreferrer" href={selectedPost.contentLink}>Content Link</a></Typography>}
                    </>}
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(mode === 'EDIT')&&<Fields fields={fieldsBefore} getValue={getValue} handleChange={handleChangeField}/>}
                    {(mode === 'EDIT' && !isPlan)&&<Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <Autocomplete
                                onChange={handleSelectPostOption}
                                inputValue={inputPost}
                                onInputChange={handleChangePostInput}
                                
                                open={openAutoComplete}
                                onOpen={() => {setOpenAutoComplete(true);}}
                                onClose={() => {setOpenAutoComplete(false);}}
                                getOptionSelected={(option, value) => option._id === value._id}
                                getOptionLabel={(option) => option.name}
                                options={options}
                                loading={loadPosts}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='postId' label='Post' margin="none" fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>}
                    {(mode !== 'DELETE')&&<Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>}
                    {(mode === 'DELETE')&&<Typography>Are you sure?</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small">
                        {(mode==='DELETE')?'No':'Cancel'}
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        {(mode==='DELETE')?'Yes':'Save'}
                    </Button>
                </DialogActions>
            </form>}
            {(mode === 'VIEW')&&<>
                <MarketingPostsDialog {...propsToChildrenPost}/>
                {selectedPost.name&&<DialogTitle>
                    {selectedPost.name} - <strong>{selectedPost.type}</strong>
                    {(versionObj && savedObjectData.version !== undefined)&&<> - {versionObj.name || ('Version' + savedObjectData.version+1)}</>}
                </DialogTitle>}
                <DialogContent dividers>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <Typography>{showDateTimeFromString(savedObjectData.dateTime, true)}</Typography>
                        {savedObjectData.status&&<Typography>{savedObjectData.status}</Typography>} 
                    </div>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <div>
                            <Typography><strong>Leads:</strong> {savedObjectData.questionnairesCount}</Typography>
                            <Typography><strong>Impressions:</strong> {savedObjectData.impressions}</Typography>
                        </div>
                        <div>
                            <Typography>{savedObjectData.type}</Typography>
                            <Typography>{savedObjectData.location}</Typography>
                            <Typography>{savedObjectData.profile}</Typography>
                        </div>
                    </div>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <div>
                            {selectedPost.contentLink&&<Typography><a target='_blank' rel="noopener noreferrer" href={selectedPost.contentLink}>Content Link</a></Typography>}
                        </div>
                        <div>
                            {savedObjectData.postedLink&&<Typography><a target='_blank' rel="noopener noreferrer" href={savedObjectData.postedLink}>Posted Link</a></Typography>}
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                        <List component="nav" dense>
                            <ListItem button onClick={()=>setTextToShow()} color='primary' selected={(!textToShow)}>
                                <ListItemText primary="Content" />
                            </ListItem>
                            {versionObj.comments&&<ListItem button onClick={()=>setTextToShow("comments")} selected={(textToShow === 'comments')}>
                                <ListItemText primary="Comments" />
                            </ListItem>}
                            {versionObj.reshareText&&<ListItem button onClick={()=>setTextToShow("reshareText")} selected={(textToShow === 'reshareText')}>
                                <ListItemText primary="ReShare Sentences" />
                            </ListItem>}
                            {versionObj.additionalText&&<ListItem button onClick={()=>setTextToShow("additionalText")} selected={(textToShow === 'additionalText')}>
                                <ListItemText primary="Additional Text" />
                            </ListItem>}
                            {versionObj.whatChanged&&<ListItem button onClick={()=>setTextToShow("whatChanged")} selected={(textToShow === 'whatChanged')}>
                                <ListItemText primary="What Changed" />
                            </ListItem>}
                        </List>
                        </div>
                        <div style={{marginTop: 8}}>
                        <Typography variant={'body2'}>
                            {savedObjectData.linkToPost&&<>
                                <a target='_blank' rel="noopener noreferrer" href={savedObjectData.linkToPost}>Link To Post</a><br/>
                            </>}
                            {savedObjectData.englishText&&<>
                                <strong>English Text</strong><br/>
                                <span dir='auto'>{savedObjectData.englishText}</span><br/>
                            </>}
                            {savedObjectData.hebrewText&&<>
                                <strong>Hebrew Text</strong><br/>
                                <span dir='auto'>{savedObjectData.hebrewText}</span><br/>
                            </>}
                        </Typography>
                            {(!textToShow)&&<Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                {versionObj.beforeCTA || ''}
                                <br/>
                                <a target='_blank' rel="noopener noreferrer" href={'https://jobhunt.co.il/?career&source='+savedObjectData.uniqueId}>{'https://jobhunt.co.il/?career&source='+savedObjectData.uniqueId}</a>
                                <br/>
                                {versionObj.afterCTA || ''}
                            </Typography>}
                            {(textToShow === 'comments' && versionObj.comments)&&<>
                                <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                    {versionObj.comments}
                                </Typography>
                            </>}
                            {(textToShow === 'reshareText' && versionObj.reshareText)&&<>
                                <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                    {versionObj.reshareText}
                                </Typography>
                            </>}
                            {(textToShow === 'additionalText' && versionObj.additionalText)&&<>
                                <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                    {versionObj.additionalText}
                                </Typography>
                            </>}
                            {(textToShow === 'whatChanged' && versionObj.whatChanged)&&<>
                                <Typography dir="auto" variant={'body2'} style={{whiteSpace: 'pre-line'}}>
                                    {versionObj.whatChanged}
                                </Typography>
                            </>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        {(savedObjectData.status!=='Published')&&<Button disabled={disabled} onClick={handleDelete} color="primary" size="small" aria-label="Delete">
                            Delete
                        </Button>}
                    </div>
                    <div>
                        <Button disabled={disabled} onClick={handlePublished} color="primary" size="small" aria-label="Published">
                            Publish
                        </Button>
                        <Button disabled={disabled} onClick={handleEdit} color="primary" size="small" aria-label="Edit">
                            Edit
                        </Button>
                        {(selectedPost && selectedPost.name)&&<Button disabled={disabled} onClick={handleEdiVersion} color="primary" size="small" aria-label="Edit Post">
                            Edit Post
                        </Button>}
                        <Button disabled={disabled} onClick={handleClose} color="primary" size="small" aria-label="Close">
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </>}
        </Dialog>
    )
}