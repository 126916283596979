import React, { useState, useEffect } from 'react';
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Fields } from '../../Fields';
import { FIELD_TYPES, SENIORITIES, capitalize } from '../../../utils/utils';
import JHApi from "../../../utils/JHApi"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { PersonalJourneyClasses } from '../../../styles/PersonalJourneyClasses';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import VideocamIcon from '@material-ui/icons/Videocam';
import EmailIcon from '@material-ui/icons/Email';

export default function InterviewsDialog(props) {
    const objectToEdit = props.objectToEdit
    const disabled = props.disabled || false
    const journeyData = props.journeyData || {}
    const interviewsSort = props.interviewsSort || 1

    const isAdmin = props.isAdmin
    const userId = props.userId
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [newData, setNewData] = useState({});

    const [searchCompany, setSearchCompany] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loadCompanies, setLoadCompanies] = useState(false);
    const [newOffer, setNewOffer] = useState({});
    const [showOfferBenefits, setShowOfferBenefits] = useState(false);

    const [errorAssignment, setErrorAssignment] = useState(false);
    const [errorChannel, setErrorChannel] = useState(false);

    const extraDataStatuses = ["Got an Offer", "They said no", "I said no"]

    useEffect(() => {
        setError()
        setNewData({...objectToEdit, reviewFreeText:'', csComment:''})
        setShowOfferBenefits(false);

        const defOffer = {
            company:(objectToEdit||{}).company, 
            title:(objectToEdit||{}).title, 
            titleGroup:(objectToEdit||{}).titleGroup, 
            titleCategories:(objectToEdit||{}).titleCategories, 
            seniority:(objectToEdit||{}).seniorityLevel
        }
        setNewOffer((objectToEdit||{}).offer || defOffer)

        setErrorAssignment(false)
        setErrorChannel(false)

        if (objectToEdit){
            setEditId(objectToEdit._id||-1)
        } else {
            setEditId()
        }
    }, [objectToEdit]);

    const handleCancel = () => {
        props.setObjectToEdit()
    }

    const handleChangeField = e => {
        const value = e.target.value
        //if (['assignmentProductChallenge', 'assignmentCodeChallenge', 'assignmentDesignChallenge', 'assignmentProfessional'].includes(e.target.name)){
        if (e.target.name === 'assignmentType'){
            setErrorAssignment(false)
        }

        if (e.target.name === 'channel'){
            setErrorChannel(false)
        }       
        
        if (e.target.name === 'type'){
            const isApproach = [
                `Self Application`,
                `Recruiter Call (Introduction)`,
                `Self Submitted Application`,
                `Incoming Email (First contact)`, 
                `Recruiter Call (Follow up)`,
                `Recruiter Call`,
                `Linkedin Approach`,
                `WhatsApp Approach`
            ].includes(e.target.value)

            let channel=''

            if(value === `Linkedin Approach`){
                channel='Linkedin'
            }

            if(value === `WhatsApp Approach`){
                channel='WhatsApp'
            }

            if([
                `Recruiter Call`,
                `Recruiter Call (Introduction)`,
                `Recruiter Call (Follow up)`,
                `Screening Interview`
            ].includes(value)){
                channel='Call'
            }
        
            if([
                `Self Submitted Application`,
                `Incoming Email (First contact)`,
                `Home Assignment`
            ].includes(value)){
                channel='Email'
            }
        
            if([
                `Presentation Interview`, `Diagnostic Workshop (מכון אבחון)`
            ].includes(value)){
                channel='On-Site'
            }
        
            if([
                `Technical Interview`
            ].includes(value)){
                channel='Video'
            }

            setNewData({...newData,[e.target.name]: value, isApproach, channel})
        } else {
            setNewData({...newData,[e.target.name]: e.target.value})
        }        
    };

    const handleChangeCompanyInput  = e => {
        if(!e || !e.target || typeof e.target.value !== 'string'){
            return
        }

        setNewData({...newData,company: e.target.value})

        const firstThreeLetters = e.target.value.substring(0, 2)

        if(!loadCompanies && e.target.value.length > 2 && searchCompany !== firstThreeLetters){
            setLoadCompanies(true)
            setSearchCompany(firstThreeLetters)

            JHApi.getCompaniesOptions({search:firstThreeLetters},(response)=>{
                setOptions(response);
                setLoadCompanies(false)
                //setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
            }, (error) => {
                setLoadCompanies(false)
            })
        } else if(e.target.value.length === 0){
            setOptions([]);
        }
    }

    const handleSelectCompanyOption = (event, newValue) => {
        const neWCompany = (newValue && newValue.name) ? newValue.name : ''

        if (newValue && newValue.urls && newValue.urls.length > 0){
            const sortedUrls = newValue.urls.sort((a, b) => {
                return b.count - a.count;
            })

            setNewData({...newData, company: neWCompany, companyUrl: sortedUrls[0].url})
        } else {
            setNewData({...newData, company: neWCompany, companyUrl: ''})
        }
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const getValueOffer = (name, defaultValue) => {
        if (newOffer[name] !== undefined){
            return newOffer[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeFieldOffer = e => {
        setNewOffer({...newOffer,[e.target.name]: e.target.value})
    };

    const handleChangeStatus = e => {
        if(extraDataStatuses.includes(e.target.value)){
            setNewData({...newData,status: e.target.value})
        } else {
            let paramsToPass = {newStatus:e.target.value, company:objectToEdit.company, interviewsSort, fetchInterviewGroups:props.fetchInterviewGroups}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            props.startEditInterviews()

            JHApi.editTalentInterviewStatus(paramsToPass,(response)=>{
                setEditId()
                setError()
                setNewData({})

                props.finishEditInterviews(response)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                props.finishEditInterviews()
            })
        }
    };

    const handleSave = e => {
        e.preventDefault();

        let isErrorSubmit = false

        if(fieldsType==='default'){
            if (newData.type === 'Home Assignment' && !newData.assignmentType
                /*!newData.assignmentProductChallenge && !newData.assignmentCodeChallenge && 
                !newData.assignmentDesignChallenge && !newData.assignmentProfessional*/
            ){
                setErrorAssignment(true)
                isErrorSubmit = true
            }

            if (!newData.channel){
                setErrorChannel(true)
                isErrorSubmit = true
            }   
        }
        
        if (isErrorSubmit){
            return
        }

        let paramsToPass = {newData, serviceToShow:props.serviceToShow, interviewsSort, newOffer, fetchInterviewGroups:props.fetchInterviewGroups}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditInterviews()

        JHApi.createEditTalentInterview(paramsToPass,(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEditInterviews(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEditInterviews()
        })
    }

    const handleExpandClick = () => {
        setShowOfferBenefits(!showOfferBenefits);
    }

    const handleChangeTitle = (titleGroup, titleCategories) => {
        if (getValue('title', '')){
            setNewData({...newData, titleGroup, titleCategories})
        } else {
            setNewData({...newData, title:titleGroup, titleGroup, titleCategories})
        }
    }

    const handleChangeTitleOffer = (titleGroup, titleCategories) => {
        if (getValueOffer('title', '')){
            setNewOffer({...newOffer, titleGroup, titleCategories})
        } else {
            setNewOffer({...newOffer, title:titleGroup, titleGroup, titleCategories})
        }
    }

    const statuses = [
        {value: "Interviewing", label:"Interviewing"},
        {value: "Got an Offer", label:"Got an Offer"},
        {value: "They said no", label:"They said no"},
        {value: "I said no", label:"I said no"},
        {value: "In process", label:"In process"},
        {value: "Waiting for feedback", label:"Waiting for feedback"},
        {value: "Last Stage Interview", label:"Last Stage Interview"},
        {value: "Just Took Information", label:"Just Took Information"},
        //{value: "No feedback", label:"No feedback"},
    ]

    const types = [
        /*{label:`Approaches:`, isGroupTitle:true},
            {label:`Recruiter Call (Introduction)`},
            {label:`Recruiter Call (Follow up)`},
            {label:`Self Submitted Application`},
            {label:`Incoming Email (First contact)`},
        {label:`Interviews:`, isGroupTitle:true},
            {label:`Screening Interview`},
            {label:`HR Interview`},
            {label:`Professional Interview`},
            {label:`Hiring Manager Interview`},
            {label:`Diagnostic Workshop (מכון אבחון)`},
            {label:`Case Interview`},
            {label:`Presentation Interview`},
            {label:`Technical Interview`},
            {label:`Assignment`}*/
        {label:`Interviews / Approaches:`, isGroupTitle:true},
            {label:`Recruiter Call`},
            {label:`Self Application`},
            {label:`HR Interview`},
            {label:`Professional Interview`},
            {label:`Presentation Interview`},
            {label:`Technical Interview`},
            {label:`Home Assignment`},
            {label:`Linkedin Approach`},
            {label:`WhatsApp Approach`}
        /*{value: "HR Interview", label:"HR Interview"},
        {value: "Professional Interview", label:"Professional Interview"},
        {value: "Technical Interview", label:"Technical Interview"},
        {value: "Phone call with a recruiter", label:"Phone call with a recruiter"},
        {value: "Zoom Interview", label:"Zoom Interview"},
        {value: "Home Assigment", label:"Home Assigment"},*/
    ]

    const assignmentTypes = [
        {name: 'assignmentProductChallenge', label:`Product Challenge`},
        {name: 'assignmentCodeChallenge', label:`Code Challenge`},
        {name: 'assignmentDesignChallenge', label:`Design Challenge`},
        {name: 'assignmentProfessional', label:`Professional Assignment`}
    ]

    let channelTypes = []

    if([
        `Self Application`,
        `Recruiter Call (Introduction)`, 
        `Recruiter Call (Follow up)`, 
        `Screening Interview`, `HR Interview`,
        `Professional Interview`,
        `Hiring Manager Interview`,
        `Technical Interview`,
        `Case Interview`
    ].includes(newData.type)){
        channelTypes.push({label:'Call', icon:<PhoneIcon fontSize="small" />})
    }

    if([
        `Self Application`,
        `Self Submitted Application`, 
        `Incoming Email (First contact)`,
        `Home Assignment`
    ].includes(newData.type)){
        channelTypes.push({label:'Email', icon:<EmailIcon fontSize="small" />})
    }

    if([
        `Screening Interview`, 
        `HR Interview`,
        `Professional Interview`,
        `Hiring Manager Interview`,
        `Case Interview`,
        `Presentation Interview`,
        `Technical Interview`,
        `Home Assignment`,
        `Diagnostic Workshop (מכון אבחון)`
    ].includes(newData.type)){
        channelTypes.push({label:'On-Site', icon:<BusinessIcon fontSize="small" />})
    }

    if([
        `Self Application`,
        `Recruiter Call (Introduction)`, 
        `Recruiter Call (Follow up)`, 
        `Screening Interview`, 
        `HR Interview`,
        `Professional Interview`,
        `Hiring Manager Interview`,
        `Case Interview`,
        `Presentation Interview`,
        `Technical Interview`,
        `Home Assignment`,
        `Diagnostic Workshop (מכון אבחון)`
    ].includes(newData.type)){
        channelTypes.push({label:'Video', icon:<VideocamIcon fontSize="small" />})
    }

    const fields = [
        {name:'seniorityLevel', label:'Seniority', defaultValue: journeyData.seniorityLevel||'', type:FIELD_TYPES.SELECT, options:SENIORITIES, sm:12, required:true},
        //{name:'interviewDate', label:'Date', defaultValue: '', type:FIELD_TYPES.DATE2, required:true, sm:12},
        {name:'type', label:'Interview Type', defaultValue: '', options:types, type:FIELD_TYPES.SELECT, required:true, sm:(channelTypes.length===0)?12:6},
        {name:'channel', label:'Channel', defaultValue: '', options:channelTypes, type:FIELD_TYPES.RADIO_ICONS, required:true, error:errorChannel, hide:(channelTypes.length===0), sm:6},
        {name:'assignmentType', label:'Assignment Type', defaultValue: '', options:assignmentTypes, type:FIELD_TYPES.RADIO, required:true, error:errorAssignment, hide:(newData.type!=='Home Assignment'), sm:12},
        {name:'reviewFreeText', label:'Client Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, sm:12, required:true},
    ]

    const titleFields = [
        {name:'titleGroup', label:'Professional Title', type:FIELD_TYPES.TITLE, handleChangeTitle, sm:12, required: true},
        {name:'title', label:'Professional Title (Free Text)', defaultValue: '', hide: (!getValue('titleGroup', '') && !getValue('title', '')) , type:FIELD_TYPES.TEXT, sm:12, required: true},
    ]
        
    const fieldsLocation = [
        {name:'location', label:'Location', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
    ]

    const fieldsInterviewer = [
        {name:'interviewerName', label:'Interviewer Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'interviewerEmail', label:'Interviewer Email', defaultValue: '', type:FIELD_TYPES.TEXT, inputType:'email', sm:12},
        {name:'interviewerPhone', label:'Interviewer Phone', defaultValue: '', type:FIELD_TYPES.TEXT, inputType:'tel', sm:12},
    ]

    const fieldsOptional = [
        //{name:'seniorityLevel', label:'Seniority', defaultValue: journeyData.seniorityLevel||'', type:FIELD_TYPES.SELECT, options:SENIORITIES, sm:12},
        {name:'companyUrl', label:'Company Url', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        //{name:'moreAboutInterview', label:'More Info About Interview', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:6, sm:12},
    ]

    const fieldsComment = [
        {name:'reviewFreeText', label:'Client Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, rows:8, sm:12, required:true},
    ]

    const fieldsCsComment = [
        {name:'csComment', label:'Manager Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, rows:8, sm:12},
    ]

    const fieldsContact = [
        {name:'contact', label:'Contact Free Text', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, sm:12},
        {name:'interviewerName', label:'Interviewer Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'interviewerEmail', label:'Interviewer Email', defaultValue: '', type:FIELD_TYPES.TEXT, inputType:'email', sm:12},
        {name:'interviewerPhone', label:'Interviewer Phone', defaultValue: '', type:FIELD_TYPES.TEXT, inputType:'tel', sm:12},
    ]

    const fieldsStatus = [
        {name:'status', label:'Status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, required:true, sm:12},
    ]

    const fieldsReason = [
        {name:'feedback', label:'Reason', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, sm:12, required:true},
    ]

    const fieldsOffer = [
        {name:'company', label:'Company', defaultValue: '', type:FIELD_TYPES.TEXT, required:true},
        {name:'titleGroup', label:'Professional Title', type:FIELD_TYPES.TITLE, handleChangeTitle:handleChangeTitleOffer, sm:6, required: true},
        {name:'title', label:'Professional Title (Free Text)', defaultValue: '', hide: (!getValueOffer('titleGroup', '') && !getValueOffer('title', '')) , type:FIELD_TYPES.TEXT, sm:6, required: true},
        {name:'seniority', label:'Seniority', defaultValue: '', type:FIELD_TYPES.SELECT, options:SENIORITIES, required:true},

        {name:'firstSalaryOffer', label:'First Salary Offer', curField:'firstSalaryCur', freqField:'firstSalaryFreq', defaultValue: '', type:FIELD_TYPES.CASH, required:true},
        {name:'finalSalary', label:'Final Salary', curField:'finalSalaryCur', freqField:'finalSalaryFreq', defaultValue: '', type:FIELD_TYPES.CASH, required:true},
        {name:'clearJobDefenition', label:'Clear Job Defenition ', defaultValue: '', type:FIELD_TYPES.TEXT, required:true},
    ]

    const fieldsOfferBenefits = [
        {name:'food', label:'Food', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'transportation', label:'Transportation', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'bonuses', label:'Bonuses', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'hybridDays', label:'Hybrid Days', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'daysOff', label:'Days Off', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'companyLocation', label:'Company Location', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'extraPerks', label:'Extra Perks', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'optionsAndStockPackage', label:'Options & Stock Package', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'cLevelTips', label:'C-Level Tips', defaultValue: '', type:FIELD_TYPES.TEXT},
    ]

    /*
        location   : { type: String },
		loc_adr    : { type: String },
		loc_lat    : { type: Number, default: 1 },
		loc_lng    : { type: Number, default: 1 },
		loc_object : { type: Object, default: {} },
		location_obj: { type: [Number], default: [1,1]},
		country     : String, 
		countryCode : String,
    */

    const fieldsType = (objectToEdit||{}).fieldsType || 'default'
    const focus = undefined //= (objectToEdit||{}).focus

    const classes = PersonalJourneyClasses(); 

    /*
    const fieldDate = [
        {name:'interviewDate', label:'Date', defaultValue: '', type:FIELD_TYPES.DATE2, required:true, sm:12}
    ]
    */

    return (
        <Dialog onClose={handleCancel} open={(editId!==undefined)} fullWidth maxWidth="sm">
            <form onSubmit={handleSave}>
                {fieldsType==='default'&&<DialogTitle disableTypography style={{display: 'flex',justifyContent: 'space-between'}}>
                    <h2 style={{display: 'flex',alignContent: 'center',flexWrap: 'wrap', margin:0}}>{(editId===-1)?'Add':'Edit'} Interview </h2>
                    <TextField
                        type="date" name="interviewDate" label='Date' margin="none" 
                        value={getValue("interviewDate", '').substr(0, 10)} onChange={handleChangeField}
                        required InputLabelProps={{shrink: true}}
                        inputProps={{lang:"GB"}}
                    />
                </DialogTitle>}
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {fieldsType==='default'&&<><Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <Autocomplete
                                onChange={handleSelectCompanyOption}
                                inputValue={getValue('company', '')}
                                onInputChange={handleChangeCompanyInput}
                                freeSolo
                                open={open}
                                onOpen={() => {setOpen(true);}}
                                onClose={() => {setOpen(false);}}
                                getOptionSelected={(option, value) => option.name === value}
                                getOptionLabel={(option) => capitalize(option.name)}
                                options={options}
                                loading={loadCompanies}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='company' label='Company' margin="none" fullWidth required
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Fields fields={titleFields} getValue={getValue} handleChange={handleChangeField} />
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField} focus={focus}/>
                    {(editId!==-1)&&<Typography style={{textAlign: 'center', margin: '16px 0'}}><strong>Optional</strong></Typography>}
                    {(editId!==-1)&&<Fields fields={fieldsOptional} getValue={getValue} handleChange={handleChangeField}  focus={focus}/>}</>}
                    {fieldsType==='location'&&<Fields fields={fieldsLocation} getValue={getValue} handleChange={handleChangeField}  focus={focus}/>}
                    {fieldsType==='interviewer'&&<Fields fields={fieldsInterviewer} getValue={getValue} handleChange={handleChangeField}  focus={focus}/>}
                    {fieldsType==='comment'&&<Fields fields={fieldsComment} getValue={getValue} handleChange={handleChangeField}  focus={focus}/>}
                    {fieldsType==='csComment'&&<Fields fields={fieldsCsComment} getValue={getValue} handleChange={handleChangeField}  focus={focus}/>}
                    {fieldsType==='contact'&&<Fields fields={fieldsContact} getValue={getValue} handleChange={handleChangeField} focus={focus}/>}
                    {fieldsType==='status'&&<>
                        <Fields fields={fieldsStatus} getValue={getValue} handleChange={handleChangeStatus} focus={focus}/>
                        {["They said no", "I said no"].includes(newData.status)&&<Fields fields={fieldsReason} getValue={getValue} handleChange={handleChangeField} focus={focus}/>}
                        {(newData.status==="Got an Offer")&&<>
                            <Typography variant='h6' style={{margin: '16px 0'}}><strong>Offer</strong></Typography>
                            <Fields fields={fieldsOffer} getValue={getValueOffer} handleChange={handleChangeFieldOffer} focus={focus} /*showData={newOffer._id!==undefined}*/ />
                            <div style={{marginTop:8, display: 'flex', alignItems: 'center'}}>
                                <Typography><strong>Benefits</strong></Typography>
                                <IconButton
                                    style={{marginLeft:0}}
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: showOfferBenefits,
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={showOfferBenefits}
                                    aria-label="show benefits"
                                    color="primary"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </div>
                            <Collapse in={showOfferBenefits} timeout="auto" unmountOnExit>
                                <Fields fields={fieldsOfferBenefits} getValue={getValueOffer} handleChange={handleChangeFieldOffer} focus={focus} /*showData={newOffer._id!==undefined}*/ />
                            </Collapse>
                        </>}
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    {(fieldsType!=='status'||(fieldsType==='status'&&extraDataStatuses.includes(newData.status)))&&<Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>}
                </DialogActions>
            </form>
        </Dialog>
    )
}